import { Injectable } from '@angular/core';
import { ProfileDataService } from './crowdbuilding-cms/services/data/personal/profile.data.service';
import { AppInfoHelper } from './helper/app-info.helper';
import { environment } from '../../environments/environment';
import { CookiePreferencesService } from './cookie-preferences.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(
    private profileDataService: ProfileDataService,
    private appInfoHelper: AppInfoHelper,
    private cookiePreferencesService: CookiePreferencesService,
  ) {
    // Noop
  }

  public initialize() {
    if (! environment.googleAnalyticsId) {
      return;
    }

    this.updateConsentPermissions();

    this.setUserId();
    this.setAppVersion();
  }

  public shareEvent(category: string, label: string) {
    // TODO: Implement
    // this.googleAnalyticsPackageService.event(
    //   GaActionEnum.SHARE,
    //   category,
    //   label
    // );
  }

  public updateConsentPermissions() {
    // TODO: Implement
    // this.googleAnalyticsPackageService.gtag('consent', 'update', {
    //   // Stores information for advertising purposes (e.g. identification of user profile for personalised advertising).
    //   ad_storage: this.cookiePreferencesService.getAdvertisingPermissionEnum(),
    //   // Stores information for statistical purposes (e.g. identification of visits for session metrics).
    //   analytics_storage: this.cookiePreferencesService.getStatisticsPermissionEnum()
    // });
  }

  private setUserId() {
    const profile = this.profileDataService.peekProfile();
    if (profile) {
      // TODO: Implement
      // this.googleAnalyticsPackageService.set({
      //   userId: profile.id
      // });
    }
  }

  private setAppVersion() {
    // TODO: Implement
    // this.appInfoHelper.getAppVersion()
    //   .then(version => {
    //     this.googleAnalyticsPackageService.set({
    //       appVersion: version
    //     });
    //   });
  }

}
